import ArticleSection from '../article-section/ArticleSection';

import ContentContainer from '../content-container/ContentContainer';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Heading } from 'dnb-ui-lib';

import * as styles from './FrontPageHero.module.scss';
import DnbPresents from '../dnb-presents/DnbPresents';

/**
 * Returns a component used as a hero styled article-link on the front-page
 * @param {title} string Specifies the title used in the hero
 * @param {subtitle} string Added underneith the title field
 * @param {image} string Specifies the image to be used as a background
 *
 */

const FrontPageHero = ({ title, subtitle, media }) => {
  return (
    <ArticleSection
      backgroundImage={media.image}
      filterStrength={0.3}
      scrollInstruct={true}
    >
      <ContentContainer>
        <div className={styles.heroContent}>
          <Heading className={styles.mainTitle}>{title}</Heading>
          <Heading className={styles.subTitle}>{subtitle}</Heading>
          {/*<DnbPresents subtitle={subtitle} />*/}
        </div>
      </ContentContainer>
    </ArticleSection>
  );
};

FrontPageHero.propTypes = {
  fullScreen: PropTypes.bool,
};

FrontPageHero.defaultProps = {
  fullScreen: false,
};

export default FrontPageHero;
