import { PropTypes } from "prop-types"
import React from "react"
import * as styles from "./FrontPageQuote.module.scss"

/**
 * Returns a figure wrapping a blockquote from Sanity portable-text
 * @param {alignment} string Specifies the text-align of the figure
 *
 */

const FrontPageQuote = ({ children, alignment }) => {
  return (
    <figure className={styles.quoteFigure} style={{ textAlign: alignment }}>
      <blockquote className={styles.miniQuote}>{children}</blockquote>
    </figure>
  )
}

FrontPageQuote.propTypes = {
  alignment: PropTypes.string,
}

FrontPageQuote.defaultProps = {
  alignment: null,
}

export default FrontPageQuote
