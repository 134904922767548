import { graphql, navigate } from "gatsby"

import Footer from "../components/footer/Footer"
import FrontPageBuilder from "../components/front-page-builder/FrontPageBuilder"
import Layout from "../components/layout/Layout"
import React from "react"
import SEO from "../components/seo/Seo"
import FrontPageHero from "../components/front-page-hero/FrontPageHero"
//import VisualiserHero from "../components/visualiser-hero/VisualiserHero"

const FrontPage = ({ data, location }) => {
  const frontpageData = data.allSanityPageLanding.edges[0].node
  const seoData = frontpageData.seoData
  return (
    <Layout overlay={true}>
      <SEO
        title={seoData && seoData.seoTitle ? seoData.seoTitle : "Havgapet"}
        description={
          seoData && seoData.seoDescription ? seoData.seoDescription : null
        }
        image={
          seoData && seoData.image ? seoData.seoImage.asset.fixed.src : null
        }
      />
      <FrontPageHero
        title={frontpageData.title}
        subtitle={frontpageData.fpSubtitle}
        media={frontpageData.fpImage}
      />
      <FrontPageBuilder sections={frontpageData.frontpageContent} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityPageLanding {
      edges {
        node {
          ...FrontPageContent
        }
      }
    }
  }
`

export default FrontPage
