import { Button, Heading, Logo } from "dnb-ui-lib/components"

import PortableText from "@sanity/block-content-to-react"
import { PropTypes } from "prop-types"
import React from "react"
import { navigate } from "gatsby"
import * as styles from "./FondAdvertisment.module.scss"

/**
 * Returns a styled component that advertises the Fondsveileder
 * @param {string} title The title of the section
 * @param {array} description Description-data in portable-text format
 * @param {string} cta Contains the button text
 */

const FondAdvertisment = ({ title, description, cta }) => {

  return (
    <div className={styles.advertismentContainer}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <Logo size="auto" className={styles.logo} />
          <Heading className={styles.title}>{title}</Heading>
        </div>
        <div className={styles.text}>
          <PortableText blocks={description} />
        </div>
        <Button
          variant="signal"
          text={cta}
          onClick={() => {
            navigate(`/miljofond`)
          }}
        />
      </div>
    </div>
  )
}

FondAdvertisment.propTypes = {
  title: PropTypes.string,
  description: PropTypes.array,
  cta: PropTypes.string.isRequired,
}

FondAdvertisment.defaultProps = {
  title: null,
  description: null,
  cta: null,
}

export default FondAdvertisment
