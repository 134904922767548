// extracted by mini-css-extract-plugin
export const intro = "AdviceGallery-module--intro--utX9m";
export const gallery = "AdviceGallery-module--gallery--_Uy7O";
export const wrapper = "AdviceGallery-module--wrapper--aeZMt";
export const cardContainer = "AdviceGallery-module--cardContainer--jLAFo";
export const card = "AdviceGallery-module--card--3lhLc";
export const image = "AdviceGallery-module--image--2US05";
export const text = "AdviceGallery-module--text--jjCL8";
export const desription = "AdviceGallery-module--desription--10IWS";
export const title = "AdviceGallery-module--title--2cOY1";
export const cta = "AdviceGallery-module--cta--1hrt2";
export const scrollNaviagtion = "AdviceGallery-module--scrollNaviagtion--s8wtq";
export const navDot = "AdviceGallery-module--navDot--88E2S";
export const active = "AdviceGallery-module--active--1Pdnb";