import AdviceGallery from '../advice-gallery/AdviceGallery';
import ArticleLinkFeatured from '../article-link-featured/ArticleLinkFeatured';
import ArticleLinkGridThree from '../article-link-grid-three/ArticleLinkGridThree';
import ArticleLinkGridTwo from '../article-link-grid-two/ArticleLinkGridTwo';
import ArticleLinkHero from '../article-link-hero/ArticleLinkHero';
import ArticleSection from '../article-section/ArticleSection';
import CallToAction from '../call-to-action/CallToAction';
import FondAdvertisment from '../fond-advertisment/FondAdvertisment';
import VisualiserAdvertisment from '../visualiser-advertisment/VisualiserAdvertisment';
import CinematicPlayer from '../cinematic-player/CinematicPlayer';

import ContentContainer from '../content-container/ContentContainer';
import FrontPageQuote from '../front-page-quote/FrontPageQuote';
import PortableText from '@sanity/block-content-to-react';
import { PropTypes } from 'prop-types';
import React from 'react';

/**
 * Returns a list of components depending on the data inputed in the article body
 * @param {sections} array A list of different component-types and their associated data
 *
 */

const descriptionSerializer = {
  types: {
    block: (props) => {
      return props.children;
    },
  },
};

const FrontPageBuilder = ({ sections }) => {
  const frontpageContent = [];
  if (sections) {
    sections.forEach((section) => {
      switch (section._type) {
        case 'largeEntry':
          if (section.article.hideArticle !== true) {
            if (section.largeEntryBgColor === true) {
              frontpageContent.push(
                <ArticleSection
                  key={section._key}
                  backgroundVideo={section.article.imageCover.video}
                  backgroundImage={
                    section.imageOverride
                      ? section.imageOverride
                      : section.article.imageCover.image
                  }
                >
                  <ContentContainer>
                    <ArticleLinkHero
                      article={section.article}
                      cta={{ text: section.buttonText }}
                      ingressOverride={section.ingressOverride}
                    />
                  </ContentContainer>
                </ArticleSection>
              );
            } else {
              frontpageContent.push(
                <ArticleSection key={section._key} theme={'dark'}>
                  <ContentContainer>
                    <ArticleLinkFeatured
                      article={section.article}
                      cta={section.buttonText}
                      ingressOverride={section.ingressOverride}
                      imageOverride={section.imageOverride}
                    />
                  </ContentContainer>
                </ArticleSection>
              );
            }
          }
          break;
        case 'articleGridTwo':
          frontpageContent.push(
            <ArticleSection key={section._key}>
              <ContentContainer>
                <ArticleLinkGridTwo
                  articles={section.articles}
                  showDescription={section.toggleDescription}
                />
              </ContentContainer>
            </ArticleSection>
          );
          break;
        case 'articleGridThree':
          frontpageContent.push(
            <ArticleSection key={section._key}>
              <ArticleLinkGridThree
                mainArticle={section.articles[0]}
                asideArticles={[section.articles[1], section.articles[2]]}
              />
            </ArticleSection>
          );
          break;
        case 'quoteBlock':
          frontpageContent.push(
            <ArticleSection
              key={section._key}
              theme={section.colorScheme ? 'dark' : 'light'}
            >
              <ContentContainer>
                <FrontPageQuote alignment={section.alignment}>
                  <PortableText
                    blocks={section.text}
                    serializers={descriptionSerializer}
                  />
                </FrontPageQuote>
              </ContentContainer>
            </ArticleSection>
          );
          break;
        case 'callToAction':
          frontpageContent.push(
            <ArticleSection key={section._key}>
              <ContentContainer>
                <CallToAction
                  title={section.ctaHeadline}
                  text={section.ctaText}
                  cta={section.ctaButtonUrlTest}
                />
              </ContentContainer>
            </ArticleSection>
          );
          break;
        case 'advice':
          frontpageContent.push(
            <AdviceGallery
              key={section._key}
              title={section.adviceHeading}
              links={section.adviceLinks}
            />
          );
          break;
        case 'fondAdvertisment':
          frontpageContent.push(
            <ArticleSection
              key={section._key}
              backgroundVideo={section.bannerBackground.video}
              backgroundImage={section.bannerBackground.image}
              filterStrength={0.5}
            >
              <ContentContainer>
                <FondAdvertisment
                  title={section.bannerHeadline}
                  description={section.bannerText}
                  cta={section.bannerCta}
                />
              </ContentContainer>
            </ArticleSection>
          );
          break;
        case 'visualiserAdvertisment':
          frontpageContent.push(
            <ArticleSection
              key={section._key}
              backgroundVideo={section.bannerBackground.video}
              backgroundImage={section.bannerBackground.image}
            >
              <ContentContainer>
                <VisualiserAdvertisment
                  title={section.bannerHeadline}
                  description={section.bannerText}
                  cta={section.bannerCta}
                />
              </ContentContainer>
            </ArticleSection>
          );
          break;
        case 'cinematicPlayer':
          frontpageContent.push(
            <ArticleSection key={section._key}>
              <ContentContainer>
                <CinematicPlayer
                  desktopVideo={section.videoDesktop}
                  mobileVideo={section.videoMobile}
                />
              </ContentContainer>
            </ArticleSection>
          );
          break;
        default:
          console.log('unkown module _type: ' + section._type);
      }
    });
  }
  return <div>{frontpageContent}</div>;
};

FrontPageBuilder.propTypes = {
  sections: PropTypes.array,
};

FrontPageBuilder.defaultProps = {
  sections: [],
};

export default FrontPageBuilder;
