import { Button, Heading } from "dnb-ui-lib/components"

import Image from "gatsby-image"
import PortableText from "@sanity/block-content-to-react"
import { PropTypes } from "prop-types"
import React from "react"
import { navigate } from "gatsby"
import * as styles from "./ArticleLinkFeatured.module.scss"

/**
 * Returns a custom styled article-link styled as a hero component
 * @param {article} object The article to be used for the article-link
 * @param {ingressOverride} array Used to override the article-lede from the article object
 * @param {imageOverride} object Used to override the article image from the article object
 * @param {cta} object Holding the button text used for the cta
 *
 */

const descriptionSerializer = {
  types: {
    block: props => {
      return props.children
    },
  },
}

const ArticleLinkFeatured = ({
  article,
  cta,
  ingressOverride,
  imageOverride,
}) => {
  return (
    <div className={styles.featurePost}>
      <Image
        className={styles.featureImage}
        fluid={
          imageOverride
            ? imageOverride.asset.fluid
            : article.imageCover.image.asset.fluid
        }
        alt={`${article.imageCover.image.alt}`}
      />
      <div className={styles.featureText}>
        <Heading className={styles.title}>{article.articleTitle}</Heading>
        <p>
          <PortableText
            blocks={ingressOverride ? ingressOverride : article.articleLede[0]}
            serializers={descriptionSerializer}
          />
        </p>
        <Button
          variant="signal"
          text={cta}
          onClick={() => {
            navigate(`/${article.slug.current}`)
          }}
        />
      </div>
    </div>
  )
}

ArticleLinkFeatured.propTypes = {
  article: PropTypes.object.isRequired,
  cta: PropTypes.string,
  ingressOverride: PropTypes.array,
  imageOverride: PropTypes.object,
}

ArticleLinkFeatured.defaultProps = {
  article: null,
  cta: "Les mer",
  ingressOverride: null,
  imageOverride: null,
}

export default ArticleLinkFeatured
