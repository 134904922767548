import ArticleLink from "../article-link/ArticleLink"
import { PropTypes } from "prop-types"
import React from "react"
import SidebarContent from "../sidebar-content/SidebarContent"
import * as styles from "./ArticleLinkGridThree.module.scss"

/**
 * Returns a grid with room for 3 articles, where one is featured and two are in the sidebar
 * @param {mainArticle} array The article to be used for the main article-link component
 * @param {articles} array A list of articles used to create article-link components for the sidebar
 *
 */

const ArticleLinkGridThree = ({ mainArticle, asideArticles }) => {
  const LinkBuilder = ({ article, type, showDescription }) => {
    return (
      <ArticleLink
        type={type}
        title={article.articleTitle}
        link={article.slug.current}
        image={article.imageCover.image}
        category={article.articleCategory}
        description={showDescription && article.articleLede}
        hidden={article.hideArticle}
      />
    )
  }
  const mainContent = () => {
    return (
      <LinkBuilder
        article={mainArticle}
        type={"large"}
        showDescription={true}
      />
    )
  }
  const articles = asideArticles.map(asideArticle => (
    <LinkBuilder article={asideArticle} type={"small"} key={asideArticle.id} />
  ))

  const articleList = () => {
    return <div className={styles.articlesContainer}>{articles}</div>
  }

  return <SidebarContent main={mainContent()} aside={articleList()} />
}

ArticleLinkGridThree.propTypes = {
  mainArticle: PropTypes.object.isRequired,
  asideArticles: PropTypes.array.isRequired,
}

ArticleLinkGridThree.defaultProps = {
  mainArticle: null,
  asideArticles: null,
}

export default ArticleLinkGridThree
