import { Button, Heading } from "dnb-ui-lib/components"

import PortableText from "@sanity/block-content-to-react"
import { PropTypes } from "prop-types"
import React from "react"
import classNames from "classnames"
import { navigate } from "gatsby"
import * as styles from "./ArticleLinkHero.module.scss"

/**
 * Returns a custom article-link for displaying links in a hero format
 * @param {alignment} string Specifies the alignment of the text
 * @param {article} object Article data used to populate the link
 * @param {ingressOverride} array Used to overwrite the article lede
 * @param {intro} object A short title added before the main title
 * @param {cta} object Holding the article slug and a button text
 *
 */

const descriptionSerializer = {
  types: {
    block: props => {
      return props.children
    },
  },
}

const ArticleLinkHero = ({
  alignment,
  intro,
  article,
  cta,
  ingressOverride,
}) => {
  return (
    <div className={classNames(styles.heroPost, styles[alignment])}>
      {intro && <p className={styles.intro}>{intro}</p>}
      <Heading className={styles.title}>{article.articleTitle}</Heading>
      <p className={styles.desc}>
        <PortableText
          blocks={ingressOverride ? ingressOverride : article.articleLede[0]}
          serializers={descriptionSerializer}
        />
      </p>
      <Button
        variant="signal"
        text={cta.text}
        onClick={() => {
          navigate(`/${article.slug.current}`)
        }}
      />
    </div>
  )
}

ArticleLinkHero.propTypes = {
  alignment: PropTypes.string.isRequired,
  intro: PropTypes.string,
  article: PropTypes.object.isRequired,
  cta: PropTypes.object,
  ingressOverride: PropTypes.array,
}

ArticleLinkHero.defaultProps = {
  alignment: "left",
  intro: null,
  article: null,
  cta: null,
  ingressOverride: null,
}

export default ArticleLinkHero
