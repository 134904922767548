import { Button, Heading } from "dnb-ui-lib/components"

import PortableText from "@sanity/block-content-to-react"
import { PropTypes } from "prop-types"
import React from "react"
import { navigate } from "gatsby"
import * as styles from "./VisualiserAdvertisment.module.scss"

/**
 * Returns a styled component that advertises the Fondsveileder
 * @param {title} string The title of the section
 * @param {description} array Description-data in portable-text format
 * @param {cta} string Contains the button text
 *
 */

const VisualiserAdvertisment = ({ title, description, cta }) => {
  return (
    <div className={styles.advertismentContainer}>
      <div className={styles.content}>
        <Heading className={styles.title}>{title}</Heading>
        <div className={styles.text}>
          <PortableText blocks={description} />
        </div>
        <Button
          variant="signal"
          text={cta}
          onClick={() => {
            navigate("/ringvirkninger", { state: { skipIntro: true } })
          }}
        />
      </div>
    </div>
  )
}

VisualiserAdvertisment.propTypes = {
  title: PropTypes.string,
  description: PropTypes.array,
  cta: PropTypes.string.isRequired,
}

VisualiserAdvertisment.defaultProps = {
  title: null,
  description: null,
  cta: null,
}

export default VisualiserAdvertisment
