import ArticleLink from "../article-link/ArticleLink"
import { PropTypes } from "prop-types"
import React from "react"
import * as styles from "./ArticleLinkGridTwo.module.scss"

/**
 * Returns a two-collumn grid with article-links
 * @param {articles} array A list of articles used to create article-link components
 * @param {showDescription} array Specifies if the description on articles should be shown
 *
 */

const ArticleLinkGridTwo = ({ articles, showDescription }) => {
  const LinkBuilder = ({ article }) => {
    return (
      <ArticleLink
        type={"large"}
        title={article.articleTitle}
        link={article.slug.current}
        image={article.imageCover.image}
        category={article.articleCategory}
        description={article.articleLede}
        showDescription={showDescription}
        hidden={article.hideArticle}
      />
    )
  }
  const articleList = articles.map(article => (
    <LinkBuilder article={article} key={article.id} />
  ))
  return <div className={styles.articleGrid}>{articleList}</div>
}

ArticleLinkGridTwo.propTypes = {
  articles: PropTypes.array,
  showDescription: PropTypes.bool,
}

ArticleLinkGridTwo.defaultProps = {
  articles: null,
  showDescription: false,
}

export default ArticleLinkGridTwo
