import ArticleSection from "../article-section/ArticleSection"
import BackgroundImage from "gatsby-background-image"
import ContentContainer from "../content-container/ContentContainer"
import Filter from "../filter/Filter"
import { Heading } from "dnb-ui-lib/components"
import { IconPrimary } from "dnb-ui-lib"
import { PropTypes } from "prop-types"
import React from "react"
import * as styles from "./AdviceGallery.module.scss"

/**
 * Returns a gallery filled with advice cards
 * @param {title} string Used as a title for the gallery
 * @param {links} array Contains the links and data for the advice cards in the gallery
 *
 */

const AdviceGallery = ({ title, links }) => {
  const cards = links.map(
    ({
      _key,
      adviceBlockImage,
      adviceBlockPretitle,
      adviceBlockTitle,
      adviceBlockUrl,
    }) => (
      <a
        href={adviceBlockUrl}
        className={styles.card}
        key={_key}
        draggable={false}
        target={"_blank"}
        rel="noreferrer"
      >
        <BackgroundImage
          Tag="div"
          className={styles.image}
          fluid={adviceBlockImage.asset.fluid}
        >
          <Filter />
          <div className={styles.text}>
            <p className={styles.desription}>{adviceBlockPretitle}</p>
            <Heading className={styles.title}>{adviceBlockTitle}</Heading>
            <IconPrimary
              className={styles.cta}
              icon="arrow_right"
              aria-hidden
            />
          </div>
        </BackgroundImage>
      </a>
    )
  )
  return (
    <ArticleSection theme={"teal"}>
      <ContentContainer>
        <p className={styles.intro}>{title}</p>
      </ContentContainer>
      <div className={styles.gallery}>
        <div className={styles.wrapper}>
          <div className={styles.cardContainer}>{cards}</div>
        </div>
      </div>
    </ArticleSection>
  )
}

AdviceGallery.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array.isRequired,
}

AdviceGallery.defaultProps = {
  title: null,
  links: null,
}

export default AdviceGallery
