import { Button, Heading } from "dnb-ui-lib/components"

import PortableText from "@sanity/block-content-to-react"
import { PropTypes } from "prop-types"
import React from "react"
import linkMaker from "../../utils/linkMaker"
import { navigate } from "gatsby"
import * as styles from "./CallToAction.module.scss"

/**
 * Returns a styled component that can be used as a CTA
 * @param {title} string The title of the section
 * @param {text} array Description-data in portable-text format
 * @param {cta} object Contains a link and a button text
 *
 */

const CallToAction = ({ title, text, cta }) => {
  const LinkedButton = ({ linkTo, text }) => {
    const linkData = linkMaker(linkTo)
    if (linkData.internal === true) {
      return (
        <Button
          variant="signal"
          text={text}
          onClick={() => {
            navigate(`${linkData.location}`)
          }}
        />
      )
    } else {
      return (
        <Button variant="signal" text={text} href={`${linkData.location}`} />
      )
    }
  }
  return (
    <div className={styles.ctaContainer}>
      <div>
        <Heading className={styles.title}>{title}</Heading>
      </div>
      <div className={styles.desc}>
        <PortableText blocks={text} />
      </div>
      <div className={styles.button}>
        <LinkedButton linkTo={cta.linkTo[0]} text={cta.text} />
      </div>
    </div>
  )
}

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
  cta: PropTypes.object.isRequired,
}

CallToAction.defaultProps = {
  title: null,
  text: null,
  cta: null,
}

export default CallToAction
